import { Auth } from "../models/auth/Auth";
import { PermissionResponse } from "../models/auth/PermissionResponse";
import { TokenResponse } from "../models/auth/TokenResponse";
import { ResponseBase } from "../models/base/ResponseBase";
import { ToastTypeEnum } from "../models/enums/ToastTypeEnum";
import { APIError } from "../models/error/APIError";
import Globals from "../models/Globals";
import { ToastModel } from "../models/ToastModel";
import APIService from "./APIService";
import ModelMapperService from "./ModelMapperService";

export default class AuthService {
    // GetToken
    static async GetToken(dataToSend: any) : Promise<TokenResponse> {
        let response: TokenResponse = new TokenResponse();

        await fetch(Globals.BASE_URL + 'token/gettoken', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
            'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.status === 401) {
                response.message = (Globals.MESSAGES.INVALID_USERNAME_OR_PASSWORD);
                console.log(responseJson);
            } else if (responseJson.authuuid !== ''){
                console.log("successfully logged in");
                console.log(responseJson);
            // Map object
            response = ModelMapperService.mapTokenFromApi(responseJson);
            //localStorage.setItem("user_id", responseJson.data.email);
            } else {
            //setErrortext(responseJson.msg);
            console.log(Globals.MESSAGES.UNKNOWN_ERROR);
            response.message = Globals.MESSAGES.UNKNOWN_ERROR;
            }
        })
        .catch((error) => {
            console.error(error);
            response.message = error;
        });
    return response;
    }

    // CheckToken
    static async ValidToken(auth: Auth) : Promise<ResponseBase> {
        let response: ResponseBase = new ResponseBase();

        await fetch(Globals.BASE_URL + 'token/validtoken', {
            method: 'GET',
            headers: {
                'token': auth.token,
                'authuuid': auth.authUuid,
                'session': auth.session
            }
        })
        .then((response) => {
            if(!response.ok) throw new APIError(response.statusText.toString(), response.status)
            else return response.json()
        })
        .then((responseJson) => {
            response = ModelMapperService.mapReponseBaseFromApi(responseJson);
        })
        .catch(async (error) => {
            if(error instanceof APIError) {
                let friendlyResponse = await APIService.ResponseHandler(error);
                response.message = friendlyResponse.message;
                response.header = friendlyResponse.header;
                response.errorCode = error.code;
                return response;
            }
            console.log("Unknown error encountered");
            console.error(error);
            response.message = error;
        })
        return response;
    }


    // GetUsersPermission
    static async GetUserPermissions(auth: Auth): Promise<PermissionResponse> {        
        let response: PermissionResponse = new PermissionResponse();
        await fetch(Globals.BASE_URL + 'permission/getUserPermissions', {
            method: 'GET',
            headers: {
                'token': auth.token,
                'authuuid': auth.authUuid,
                'session': auth.session
            }
        })
        .then((response) => {
            if(!response.ok) throw new APIError(response.statusText.toString(), response.status)
            else return response.json()
        })
        .then((responseJson) => {
            response = ModelMapperService.mapPermissionsFromApi(responseJson);
        })
        .catch(async (error) => {
            if(error instanceof APIError) {
                let friendlyResponse = await APIService.ResponseHandler(error);
                response.message = friendlyResponse.message;
                response.header = friendlyResponse.header;
                response.errorCode = error.code;
                return response;
            }
            console.log("Unknown error encountered");
            console.error(error);
            response.message = error;
        })
        return response;
    }

    // Check Token
    static async CheckToken(auth: Auth, addToast: (toast: ToastModel) => void, navigation: (url: string) => void): Promise<boolean> {
        if(auth.tokenExpiration < new Date()) {
          addToast(new ToastModel(Globals.HEADERS.TOKEN_EXPIRED, Globals.MESSAGES.TOKEN_EXPIRED, ToastTypeEnum.error));
          navigation('/logout');
          return false;
        } else {
          let response = await AuthService.ValidToken(auth);
          if(response.success) {
            return true;
          } else {
            addToast(new ToastModel(Globals.HEADERS.TOKEN_INVALID, Globals.MESSAGES.TOKEN_INVALID, ToastTypeEnum.error));
            navigation('/logout');
            return false;
          }
        }
    }

    // Remove Session
    static async RemoveSession(auth: Auth): Promise<ResponseBase> {
        let response: ResponseBase = new ResponseBase();

        await fetch(Globals.BASE_URL + 'token/removesession', {
            method: 'POST',
            headers: {
                'token': auth.token,
                'authuuid': auth.authUuid,
                'session': auth.session
            }
        })
        .then((_response) => {
            console.log(response);
            if(!_response.ok) throw new APIError(_response.statusText.toString(), _response.status)
            else response.success = true;
        })
        .catch(async (error) => {
            if(error instanceof APIError) {
                let friendlyResponse = await APIService.ResponseHandler(error);
                response.message = friendlyResponse.message;
                response.header = friendlyResponse.header;
                response.errorCode = error.code;
                return response;
            }
            console.log("Unknown error encountered");
            console.error(error);
            response.message = error;
        })
        return response;
    }
    
};
