import React, { Component, useEffect, useState } from 'react';
import './LoadingComponent.css';

export type LoadingComponentProps = {
    show: boolean;
}

const LoadingComponet = (props: LoadingComponentProps) => {

 

    return (
        <>
            {props.show &&
            <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            }
        </>
        
    );
}

export default LoadingComponet;