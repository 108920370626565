import React, { useState, createContext } from 'react';
import { Auth } from '../models/auth/Auth';
import { Permission } from '../models/auth/Permission';
import { ToastModel } from '../models/ToastModel';

export type GlobalContextType = {
    toasts: Array<ToastModel>;
    setToasts: (toasts: Array<ToastModel>) => void;
    addToast: (toast: ToastModel) => void;
    closeToast: (index: number) => void;
    loadToastsFromLocalStorage: () => void;
    getToastCount: () => number;
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

export const GlobalContextProvider: any = ({ children }: any) => {
  const [toasts, setToasts] = useState<Array<ToastModel>>(new Array<ToastModel>);

  const addToast = (toast: ToastModel) => {
    const _toasts = [...toasts];  
    _toasts.push(toast);
    console.log("Adding Toast");
    localStorage.setItem("toasts", JSON.stringify(_toasts));
    setToasts(_toasts);
  }

  const closeToast = (index: number) => {
    const _toasts = [...toasts];
    _toasts.splice(index, 1);
    localStorage.setItem("toasts", JSON.stringify(_toasts));
    setToasts(_toasts);
  }

  const loadToastsFromLocalStorage = () => {
    let _toasts = localStorage.getItem("toasts");
    if(_toasts !== null) {
      try {
        setToasts(JSON.parse(_toasts));
      } catch {
        setToasts(new Array<ToastModel>);
      }
    }
  }

  const getToastCount = () : number => {
    return toasts.length;
  }

  return <GlobalContext.Provider value={{toasts, setToasts, addToast, closeToast, loadToastsFromLocalStorage, getToastCount}}>{children}</GlobalContext.Provider>
};