import React, { ChangeEvent, Component, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LoadingComponet from '../components/generic/LoadingComponent';
import {AuthContext, AuthContextType } from '../contexts/AuthContext';
import APIService from '../services/APIService';
import CommonFunctions from '../utils/CommonFunctions';
import './TestScreen.css';

const TestScreen = () => {
    const {auth, updateValidToken} = useContext(AuthContext) as AuthContextType;
    const params = useParams();
    const [policyText, setPolicyText] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("Test Screen trigger");
        runTest();
        //runInvalidateToken();
      }, [auth]);

    const runTest = async() => {
        await CommonFunctions.delay(5000);
        console.log("trigger Run Test");
        let permissions = await APIService.GetAllPermissions(auth);
        console.log("All Permissions:");
        console.log(permissions);
        setLoading(false);
    }

    const runInvalidateToken = async() => {
        if(auth.permissionsChecked) {
            await CommonFunctions.delay(15000);
            console.log("invalidating token");
            updateValidToken(false);
        }
    }

    return (
        <div>
            <h1>Test Screen { (params?.application) && ' - ' + params.application}</h1>
            <div className="card">
                <div className="card-body">
                    <LoadingComponet show={loading} />
                    <p>{policyText}</p>
                </div>
            </div>
            
        </div>
    );
}

export default TestScreen;