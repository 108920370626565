import React, { ChangeEvent, Component, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import AuthService from '../../services/AuthService';
import './UpdatePasswordScreen.css';

const UpdatePasswordScreen = () => {
  const navigation = useNavigate();
  
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");


  return (
    <div>
      Update Password Screen.
    </div>
  );
}

export default UpdatePasswordScreen;