import React, { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AuthContext, AuthContextType } from '../../contexts/AuthContext';
import { Auth } from '../../models/auth/Auth';
import Globals from '../../models/Globals';
import AuthService from '../../services/AuthService';
import './LoginScreen.css';

//const LoginScreen = (props: LoginScreenProps) => {
const LoginScreen = () => {
  const navigation: NavigateFunction = useNavigate();

  const [validUsername, setValidUsername] = useState<boolean | null>(null);
  const [validPassword, setValidPassword] = useState<boolean | null>(null);
  const [errorText, setErrorText] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const {auth, setAuth} = useContext(AuthContext) as AuthContextType;
  //console.log(useContext(AppContext));
  
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === 'username'){
      if(validUsername === false)
        setValidUsername(true);
      setUsername(value);
    }
      
    if(name === 'password'){
      if(validPassword === false)
        setValidPassword(true);
      setPassword(value);
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let valid: boolean = true;
    if(username === ""){
      setValidUsername(false);
      valid = false;
    }

    if(password === ""){
      setValidPassword(false);
      valid = false;
    }

    if(!valid) {
      return;
    }

    setShowLoader(true);
    let dataToSend: any = {username: username.trim(), password: password};
    
    let tokenResponse = await AuthService.GetToken(dataToSend);

    setShowLoader(false);

    if(!tokenResponse.success) {
      setErrorText(tokenResponse.message);
      return;
    }

    localStorage.setItem("authUuid", tokenResponse.authuuid);
    localStorage.setItem("username", username);
    localStorage.setItem('session', tokenResponse.session);
    localStorage.setItem('token', tokenResponse.token);
    localStorage.setItem('tokenExpiration', JSON.stringify(tokenResponse.tokenExpiration));
    let auth = new Auth();
    auth.authUuid = tokenResponse.authuuid;
    auth.username = username;
    auth.session = tokenResponse.session;
    auth.token = tokenResponse.token;
    auth.tokenExpiration = tokenResponse.tokenExpiration;
    auth.loggedIn = true;
    auth.validToken = true;
    //TODO set Permissions
    auth.permissions = [];
    setAuth(auth);
    navigation('/');
  }

  return (
    <div>
      
      <div className="col-xl-6 offset-xl-3 b-radius-3">
        <div className="login-background">
          <div className="d-flex flex-column align-items-center">
            <h2 className="login-header mt-2">Login</h2>
          </div>
          
          <form className="d-flex flex-column p-5 justify-content-center" onSubmit={handleSubmit}>
            
              <label className="flex-fill mx-4">
                Username:
                <input type="text" name="username" className={"form-control " + ((validUsername === false) ? 'is-invalid' : (validUsername === true) ? 'is-valid' : '')} value={username} onChange={handleInputChange} required />
              </label>

              <label className="mt-3 mx-4">
                Password:
                <input type="password" name="password" className={"form-control " + ((validPassword === false) ? 'is-invalid' : (validPassword === true) ? 'is-valid' : '')} value={password} onChange={handleInputChange} required />
              </label>
              
              <div className="d-flex flex-column align-items-center">
                <input type="submit" value="Submit" className="btn btn-outline-success mt-4" onClick={handleSubmit} />
                <div className="d-flex flex-row align-items-end justify-content-between">
                  <div className={"spinner-border text-primary mt-2" + ((!showLoader) ? " d-none" : "")} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>

                <div className={'text-danger mt-3 fw-bold' + ((errorText.length > 0) ? "" : ' d-none')}>{errorText}</div>
              </div>
          </form>

          
        </div>
        
      </div>
    </div>
  );
}

export default LoginScreen;