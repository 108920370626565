import React, { useState, createContext } from 'react';
import { Auth } from '../models/auth/Auth';
import { Permission } from '../models/auth/Permission';

export type AuthContextType = {
    auth: Auth;
    setAuth: (auth: Auth) => void;
    updatePermissions: (permissions?: Array<Permission>) => void;
    updateValidToken: (validToken: boolean) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthContextProvider: any = ({ children }: any) => {
  const [auth, setAuth] = useState<Auth>(new Auth());

  const updatePermissions = (permissions?: Array<Permission>) => {
    setAuth({
      ...auth,
      permissions: permissions ?? [],
      permissionsChecked: true,
    });
  }

  const updateValidToken = (validToken: boolean) => {
    setAuth({
      ...auth,
      validToken: validToken
    })
  }

  return <AuthContext.Provider value={{auth, setAuth, updatePermissions, updateValidToken}}>{children}</AuthContext.Provider>
};