import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppRoutes, { AuthenticatedAppRoutes, AdminAppRoutes } from './AppRoutes';
import { AuthContext, AuthContextType } from './contexts/AuthContext';
import {GlobalContext, GlobalContextType } from './contexts/GlobalContext';
import { Auth } from './models/auth/Auth';
import { ToastTypeEnum } from './models/enums/ToastTypeEnum';
import Globals from './models/Globals';
import { ToastModel } from './models/ToastModel';
import AuthService from './services/AuthService';
import CommonFunctions from './utils/CommonFunctions';

const AppAuthAndRouteManager = () => {
    const {auth, setAuth, updatePermissions} = useContext(AuthContext) as AuthContextType;
    const {addToast} = useContext(GlobalContext) as GlobalContextType;
    const navigation: NavigateFunction = useNavigate();
    const [abortControllers, setAbortControllers] = useState<Array<AbortController>>(new Array<AbortController>());
  
  useEffect(() => {
    console.log("Auth And Router Manager Effect");
    if(auth.authUuid === "" && localStorage.getItem("authUuid") !== null) {
      let _auth = new Auth();
      _auth.authUuid = localStorage.getItem('authUuid') ?? "";
      _auth.username = localStorage.getItem('username') ?? "";
      _auth.session = localStorage.getItem('session') ?? "";
      _auth.token = localStorage.getItem('token') ?? "";
      let _expiration = localStorage.getItem('tokenExpiration') ?? null;
      if(_expiration === null) {
        _auth.tokenExpiration = new Date();
      } else {
        _auth.tokenExpiration = new Date(JSON.parse(_expiration));
      }
      _auth.loggedIn = true;
      //TODO check valid token
      _auth.validToken = true;
      // Get permissions
      setAuth(_auth);
      
    }
    if(auth.authUuid !== "" && !auth.permissionsChecked) {
      getUserPermissions();
    }

    if(auth.authUuid !== "" && auth.permissionsChecked && !auth.validToken) {
      addToast(new ToastModel(Globals.HEADERS.TOKEN_INVALID, Globals.MESSAGES.TOKEN_INVALID, ToastTypeEnum.error));
      navigation("/logout");
    }

    // Register HeartBeat
    registerHeartBeat(abortPreviousHeartbeats().signal, 0);

    onScreenLoad();

  }, [auth]);

  const getUserPermissions = async() => {
    let response = await AuthService.GetUserPermissions(auth);
    if(response.success) {
      updatePermissions(response.permissions);
    } else {
      addToast(new ToastModel(response.header, response.message, ToastTypeEnum.error));
      if(response.errorCode === 401) {
        // Unauthorized, check token
        AuthService.CheckToken(auth, addToast, navigation);
      }
    }
  }

  const registerHeartBeat = async(signal: AbortSignal, count: number) => {
    try {
      if(count < 2) {
        await CommonFunctions.delay(2 * 1000);
      } else {
        count = 3;
        await CommonFunctions.delay(5 * 1000 * 60);
      }
      
      if(auth.authUuid !== "") {
        if(auth.tokenExpiration < new Date()) {
          addToast(new ToastModel(Globals.HEADERS.TOKEN_EXPIRED, Globals.MESSAGES.TOKEN_EXPIRED, ToastTypeEnum.error));
          navigation("/logout");
        }
      }
      
    } catch(error) {
      if(error instanceof Error) {
        console.log('Heartbeat-error:', error.message);
      } else {
        console.log("Unknown Heatbeat Error:");
      }
    } finally {
      if(!signal.aborted)
        registerHeartBeat(signal, ++count);
    }
  }

  const abortPreviousHeartbeats = (): AbortController => {
    abortControllers.forEach(f => {
      f.abort();
    });
    const _abortControllers = [...abortControllers];  
    _abortControllers.push(new AbortController());
    setAbortControllers(_abortControllers);
    return (_abortControllers.slice(-1)[0]);
  }

  const onScreenLoad = async() => {
    await CommonFunctions.delay(5000);
    console.log("delay Trigger");
    console.log(auth);
  }

  return (
    <Routes>
        {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
        })}
        {auth.loggedIn &&
            AuthenticatedAppRoutes.AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })
        }
        {
        auth.loggedIn && (auth.permissions.filter(e => e.name === Globals.PERMISSIONS.ADMIN).length > 0) &&
            AdminAppRoutes.AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })
        }
    </Routes>
  );
}

export default AppAuthAndRouteManager;