import React from 'react';
import Layout from './components/Layout';
import './App.css';
import {AuthContextProvider, AuthContext, AuthContextType } from './contexts/AuthContext';
import AppAuthAndRouteManager from './AppAuthAndRouteManager';
import {GlobalContextProvider} from './contexts/GlobalContext';

const App = () => {
  return (
    <GlobalContextProvider>
      <AuthContextProvider>
        <Layout>
          <AppAuthAndRouteManager />
        </Layout>
      </AuthContextProvider>
    </GlobalContextProvider>
  );
};

export default App;