import React, { ChangeEvent, Component, SyntheticEvent, useContext, useEffect, useState } from 'react';
import {GlobalContext, GlobalContextType } from '../contexts/GlobalContext';
import { ToastTypeEnum } from '../models/enums/ToastTypeEnum';
import { ToastModel } from '../models/ToastModel';
import './PersonScreen.css';

const PersonScreen = () => {
  const [people, setpeople] = useState([{'name': 'Jon', 'age': 30},{'name': 'bob', 'age': 50}]);

  const {addToast, getToastCount} = useContext(GlobalContext) as GlobalContextType;

  useEffect(() => {
    console.log('Person Effect Trigger');
    console.log(people);
  }, [people]);
      
    
    const addPerson = () => {
      const _people = [...people];  
      _people.push({'name': 'sue', 'age': 15});
      setpeople(_people);
    }

    const clickAddToast = (i: number) => {
      let toast: ToastModel = new ToastModel();
      toast.body = "Test Toast" + getToastCount();
      toast.header = "Test Header";
      switch(i) {
        case 1:
          toast.type = ToastTypeEnum.success;
          break;
        case 2:
          toast.type = ToastTypeEnum.info;
          break;
        case 3: 
          toast.type = ToastTypeEnum.warning;
          break;
        case 4:
          toast.type = ToastTypeEnum.error;
          break;
        case 5:
          //toast.type = ToastTypeEnum.error;
          break;
      }

      addToast(toast);
    }

    return (
      <div>
        <h1>Person Screen.</h1>

        <div className="card mt-5">
          <div className="card-header fw-bold">Permissions</div>
          <div className="card-body">
            {
              people.map((person: any, index: any) => {
                return <p key={index}>Name: {person.name} Age: {person.age}</p>;
              })
            }
            <button onClick={addPerson} className="btn btn-success">Add Person</button>
          </div>
        </div>

        <div className="card mt-2">
          <div className="card-header fw-bold">Notifications</div>
          <div className="card-body">
            <button onClick={() => clickAddToast(1)} className="btn btn-info ms-2">Add Success Toast</button>
            <button onClick={() => clickAddToast(2)} className="btn btn-info ms-2">Add Info Toast</button>
            <button onClick={() => clickAddToast(3)} className="btn btn-info ms-2">Add Warning Toast</button>
            <button onClick={() => clickAddToast(4)} className="btn btn-info ms-2">Add Error Toast</button>
            <button onClick={() => clickAddToast(5)} className="btn btn-info ms-2">Add Undefined Toast</button>
          </div>
        </div>
      </div>
    );
}

export default PersonScreen;
