import React, { Component, useContext, useEffect, useState } from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';
import { FaUserCircle, FaWrench } from 'react-icons/fa'
import Globals from '../models/Globals';


const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [adminDropDownOpen, setAdminDropDownOpen] = useState(false);

  const {auth, setAuth} = useContext(AuthContext) as AuthContextType;

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const toggleDropDown = (menu: string) => {
    switch(menu) {
      case 'admin':
        setAdminDropDownOpen(!adminDropDownOpen);
        break;
      case 'authenticated':
        setDropDownOpen(!dropdownOpen);
        break;
    }
    //setDropDownOpen(!dropdownOpen);
  }

  // useEffect(() => {
  //   console.log("nav:");
  //   console.log(auth);
  // }, [auth]);

  return (
    
    <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">chattdroid.com</NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow align-items-center">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/policy">Policy</NavLink>
              </NavItem>

              {!auth.loggedIn &&
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/login"><button className="btn btn-primary">Login</button></NavLink>
                </NavItem>
              }

              {auth.loggedIn && (auth.permissions.filter(e => e.name === Globals.PERMISSIONS.ADMIN).length > 0) &&
                <Dropdown isOpen={adminDropDownOpen} toggle={() => toggleDropDown('admin')}>
                  <DropdownToggle caret color="" className="dropdown-toggle"><FaWrench className="menu-dropdown-icon" />&nbsp;Admin</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to="/manage-application">Manage Application</DropdownItem>
                    <DropdownItem tag={Link} to="/manage-permissions">Manage Permissions</DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem tag={Link} to="/create-user">Create User</DropdownItem>
                    <DropdownItem tag={Link} to="/manage-users">Manage Users</DropdownItem>
                    <DropdownItem tag={Link} to="/manage-users-permissions">Manage Users Permissions</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              }

              {auth.loggedIn &&
                <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropDown('authenticated')}>
                  <DropdownToggle caret color="" className="dropdown-toggle"><FaUserCircle className="menu-dropdown-icon" />&nbsp;{auth.username}</DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem header>Account</DropdownItem> */}
                    <DropdownItem tag={Link} to="/account">Account</DropdownItem>
                    <DropdownItem tag={Link} to="/update-password">Update Password</DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem tag={Link} to="/logout">Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              }

              

            </ul>
          </Collapse>
        </Navbar>
      </header>
  );
}
export default NavMenu;