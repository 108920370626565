import React, { ChangeEvent, Component, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LoadingComponet from '../components/generic/LoadingComponent';
import APIService from '../services/APIService';
import CommonFunctions from '../utils/CommonFunctions';
import './PolicyScreen.css';

const PolicyScreen = () => {
    const params = useParams();
    const [policyText, setPolicyText] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("Policy Screen trigger");
        console.log(params);
        getPolicy();
      }, []);

    const getPolicy = async() => {
        let tokenResponse = await APIService.GetPolicy(params?.application);
        setPolicyText(tokenResponse.Policy?.policyWording ?? "");
        setLoading(false);
    }

    return (
        <div>
            <h1>Privacy Policy { (params?.application) && ' - ' + params.application}</h1>
            <br />
            <div className="card">
                <div className="card-body policy-screen-policy-container">
                    <LoadingComponet show={loading} />
                    <div className="content" dangerouslySetInnerHTML={{__html: policyText}}></div>
                </div>
            </div>
        </div>
    );
}

export default PolicyScreen;