import { Policy } from "../Policy";
import { ResponseBase } from "../base/ResponseBase";

export class PolicyResponse extends ResponseBase {
    Policy?: Policy;
}

/*
import { ResponseBase } from "../base/ResponseBase";
import { Permission } from "./Permission";

export class PermissionResponse extends ResponseBase {
    permissions?: Array<Permission>;
}
*/