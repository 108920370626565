import React, { ChangeEvent, Component, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext, AuthContextType } from '../../contexts/AuthContext';
import AuthService from '../../services/AuthService';
import './AccountScreen.css';

const AccountScreen = () => {
  const navigation = useNavigate();
  const {auth, setAuth} = useContext(AuthContext) as AuthContextType;

  return (
    <div>
      <h1>Account Screen.</h1>
      
      <div className="card mt-5">
        <div className="card-header fw-bold">Permissions</div>
        <div className="card-body">
          {
            auth.permissions.map((permission: any, index: any) => {
              return <p key={index}>{permission.friendlyName}</p>;
            })
          }
        </div>
      </div>
    </div>
  );
}

export default AccountScreen;