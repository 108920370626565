import React, { ChangeEvent, Component, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import AuthService from '../../services/AuthService';
import './UpdateEmailScreen.css';

const UpdatEmailScreen = () => {
  const navigation = useNavigate();
  
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");


  return (
    <div>
      Update Email Screen.
    </div>
  );
}

export default UpdatEmailScreen;