import { ReactNode } from "react";
import { ToastTypeEnum } from "./enums/ToastTypeEnum";

export class ToastModel {
    header?: string;
    body?: string;
    type?: ToastTypeEnum;
    icon?: ReactNode;

    constructor(header?: string, body?: string, type?: ToastTypeEnum, icon?: ReactNode) {
        if(typeof(header) !== undefined)
            this.header = header;
        if(typeof(body) !== undefined)
            this.body = body;
        if(typeof(type) !== undefined)
            this.type = type;
        if(typeof(icon) !== undefined)
            this.icon = icon;
    }
}