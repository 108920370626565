import { Auth } from "../models/auth/Auth";
import { PermissionResponse } from "../models/auth/PermissionResponse";
import { ResponseBase } from "../models/base/ResponseBase";
import { APIError } from "../models/error/APIError";
import Globals from "../models/Globals";
import { JSONAPIResponse } from "../models/response/JSONAPIResponse";
import { PolicyResponse } from "../models/response/PolicyResponse";
import AuthService from "./AuthService";
import ModelMapperService from "./ModelMapperService";

export default class APIService {

    // Get Policy
    static async GetPolicy(id?: string): Promise<PolicyResponse> {
        let response: PolicyResponse = new PolicyResponse();
        if(id === undefined) id = "";

        await fetch(Globals.BASE_URL + 'policy/' + id, {
            method: 'GET'
        })
        .then((_response) => {
            console.log(response);
            if(!_response.ok) throw new APIError(_response.statusText.toString(), _response.status)
            else return _response.json()
        })
        .then((responseJson) => {
            console.log(responseJson);
            response.Policy = ModelMapperService.mapPolicyFromApi(responseJson);
        })
        .catch(async (error) => {
            if(error instanceof APIError) {
                let friendlyResponse = await APIService.ResponseHandler(error);
                response.message = friendlyResponse.message;
                response.header = friendlyResponse.header;
                response.errorCode = error.code;
                return response;
            }
            console.log("Unknown error encountered");
            console.error(error);
            response.message = error;
        })
        return response;
    }

    // Get All Permissions
    static async GetAllPermissions(auth: Auth): Promise<PermissionResponse> {
        let result = await this.AuthorizedAPIHandler(auth, Globals.BASE_URL + 'permission/GetAllPermissions');
        if(!result.success)
            return ModelMapperService.MapApiFailure(PermissionResponse, result);
        return ModelMapperService.mapPermissionsFromApi(result.Json);        
    }
    
    

    /* ******************************************************************************************************************************************** */
    /* ******************************************************************************************************************************************** */
    /* ******************************************************************************************************************************************** */


    // Authorize API Handler
    private static async AuthorizedAPIHandler(auth: Auth, url: string): Promise<JSONAPIResponse> {
        let returnResponse: JSONAPIResponse = new JSONAPIResponse();
        if(!auth.loggedIn){
            returnResponse.success = false;
            returnResponse.message = "Unauthorized";
            returnResponse.errorCode = 401;
            return returnResponse;
        }

        await fetch(url , {
            method: 'GET',
            headers: {
                'token': auth.token,
                'authuuid': auth.authUuid,
                'session': auth.session
            }
        })
        .then((response) => {
            if(!response.ok) throw new APIError(response.statusText.toString(), response.status)
            else return response.json()
        })
        .then((responseJson) => {
            returnResponse.Json = responseJson;
            returnResponse.success = true;
        })
        .catch(async (error) => {
            if(error instanceof APIError) {
                console.error("ApiError");
                let friendlyResponse = await APIService.ResponseHandler(error, auth);
                returnResponse.message = friendlyResponse.message;
                returnResponse.header = friendlyResponse.header;
                returnResponse.errorCode = error.code;
                returnResponse.success = false;
                return returnResponse;
            }
            console.log("Unknown error encountered");
            console.error(error);
            returnResponse.message = error;
        })
        return returnResponse;
    }
    
    // Response Handler
    public static async ResponseHandler(error: APIError, auth?: Auth): Promise<{message: string, header: string}> {
        switch (error.code) {
            case 500:
                return {message: error.message, header: "Error: 500" };
            case 401:{
                if(auth !== undefined) {
                    console.log("Checking Token");
                    let tokenResponse = await AuthService.ValidToken(auth);
                    if(tokenResponse.message !== "Valid") {
                        // Invalid Token
                        console.log("Invalid Token Response");
                        // Force a logout
                        //auth.validToken = false;
                        //TODO Force a logout.
                        console.log(tokenResponse);
                    }
                    
                }
                return {message: error.message, header: "Unauthorized: 401" };
            }
            default:
                return {message: error.message, header: "Error"};
        }
    }
}
