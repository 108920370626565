import React from 'react';
import { Container } from 'reactstrap';
import { LayoutProps } from '../models/types';
import NavMenu from './NavMenu';
import ToastComponent from './ToastComponent';

const Layout = (props: LayoutProps) => {
  return (
    <div>
      <NavMenu />
      <ToastComponent />
      <Container>
        {props.children}
      </Container>
    </div>
  );
}

export default Layout;