import React, { ReactElement, useContext, useEffect } from 'react';
import { FaBaby, FaCheckSquare, FaExclamationTriangle, FaQuestion, FaSquare, FaTimesCircle } from 'react-icons/fa';
import { Toast, ToastHeader, Spinner, ToastBody } from 'reactstrap';
import { GlobalContext, GlobalContextType } from '../contexts/GlobalContext';
import { ToastTypeEnum } from '../models/enums/ToastTypeEnum';
import { ToastModel } from '../models/ToastModel';
import './ToastComponent.css';

const ToastComponent = () => {
    const {toasts, setToasts, addToast, closeToast, loadToastsFromLocalStorage} = useContext(GlobalContext) as GlobalContextType;

    useEffect(() => {
      console.log("ToastComponent Loaded");
        loadToastsFromLocalStorage();
    }, []);

    const getDefaultIcon = (toastType?: ToastTypeEnum): ReactElement => {
        let _icon = <FaBaby />;
        switch (toastType) {
            case ToastTypeEnum.warning:
                return <FaExclamationTriangle />
            case ToastTypeEnum.error:
                return <FaTimesCircle />
            case ToastTypeEnum.success:
                return <FaCheckSquare />
            case ToastTypeEnum.info:
                return <FaQuestion />
            default:
                return <FaSquare />

        }
    }

    return (
        <div className="d-flex justify-content-end flex-row me-5">
          <div className="d-flex flex-column align-content-end justify-content-start toast-message-container">

            {
              toasts.map((toast: ToastModel, index: number) => {
                return (
                  <Toast className={"toast-message mb-3 " + ((toast.type !== undefined) ? toast.type.toString() : 'toast-info')} key={index}>
                    <ToastHeader toggle={() => {closeToast(index);}} icon={((toast.icon !== undefined) ? toast.icon : getDefaultIcon(toast.type))}>
                      {toast.header}
                    </ToastHeader>
                    <ToastBody>
                      {toast.body}
                    </ToastBody>
                  </Toast>
                );
              })
            }
          </div>
        </div>
    );
}

export default ToastComponent;