import { Permission } from "../models/auth/Permission";
import { PermissionResponse } from "../models/auth/PermissionResponse";
import { TokenResponse } from "../models/auth/TokenResponse";
import { ResponseBase } from "../models/base/ResponseBase";
import { Policy } from "../models/Policy";
import { JSONAPIResponse } from "../models/response/JSONAPIResponse";

export default class ModelMapperService {
    static mapObject(object1: any): any {

    }

    static MapApiFailure<T extends ResponseBase>(type: {new(): T; }, jsonResponse: JSONAPIResponse): T {
        let mapped = new type();
        mapped.errorCode = jsonResponse.errorCode;
        mapped.header = jsonResponse.header;
        mapped.message = jsonResponse.message;
        mapped.success = jsonResponse.success;
        return mapped;
    }

    static mapTokenFromApi(responseJson: any): TokenResponse {
        let response: TokenResponse = new TokenResponse();
        response.success = true;
        response.authuuid = responseJson.authuuid; 
        response.session = responseJson.session;
        response.token = responseJson.token;
        response.tokenExpiration = new Date(responseJson.tokenExpiration);
        return response;
    }

    static mapPermissionsFromApi(responseJson: any): PermissionResponse {
        let response: PermissionResponse = new PermissionResponse();
        response.success = true;
        response.permissions = [];
        responseJson.forEach((e: any) => {
            response.permissions?.push(this.mapPermissionFromApi(e));
        });

        return response;
    }

    static mapPermissionFromApi(permission: any): Permission {
        let permissionResponse: Permission = new Permission();
        permissionResponse.friendlyName = permission.friendlyName;
        permissionResponse.uuid = permission.uuid;
        permissionResponse.name = permission.name;
        return permissionResponse;
    }

    static mapReponseBaseFromApi(responseJson: any): ResponseBase {
        let baseResponse: ResponseBase = new ResponseBase();
        baseResponse.success = responseJson.success;
        baseResponse.message = responseJson.message;
        baseResponse.errorCode = responseJson.errorCode;
        baseResponse.header = responseJson.header;
        return baseResponse;
    }

    static mapPolicyFromApi(responseJson: any): Policy {
        let policyResponse: Policy = new Policy();
        policyResponse.id = responseJson.id;
        policyResponse.applicationId = responseJson.applicationId;
        policyResponse.version = responseJson.version;
        policyResponse.policyType = responseJson.policyType;
        policyResponse.policyWording = responseJson.policyWording;
        return policyResponse;
    }
}