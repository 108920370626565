import { Permission } from "./Permission";

export class Auth {
    authUuid: string;
    username: string;
    session: string;
    token: string;
    tokenExpiration: Date;
    loggedIn: boolean;
    validToken: boolean;
    permissions: Permission[];
    permissionsChecked: boolean;

    constructor() {
        this.authUuid = "";
        this.username = "";
        this.session = "";
        this.token = "";
        this.tokenExpiration = new Date();
        this.loggedIn = false;
        this.validToken = false;

        this.permissions = [];
        this.permissionsChecked = false;
    }
}