export default {
    BASE_URL: (process.env.NODE_ENV === "development") ? 'https://localhost:7005/api/' : 'https://chattdroid.com/api/',
    MESSAGES: {
        UNKNOWN_ERROR: 'An Unknown Error Has Occurred. Please Try again later.',
        INVALID_USERNAME_OR_PASSWORD: 'Invalid Username or Password',
        TOKEN_EXPIRED: 'Token Expired: Please Login again.',
        TOKEN_INVALID: 'Token Invalid: Please Login again.',
    },
    HEADERS: {
        TOKEN_EXPIRED: 'Expired Token',
        TOKEN_INVALID: 'Token Invalid',
    },
    PERMISSIONS: {
        ADMIN: 'is_admin',
    }
}