import React, { useContext } from "react";
import CounterScreen from "./components/Counter";
import { FetchData } from "./components/FetchData";
import LoginScreen from "./screens/auth/LoginScreen";
import HomeScreen from "./components/Home";
import UpdatePasswordScreen from "./screens/auth/UpdatePasswordScreen";
import LogoutScreen from "./screens/auth/LogoutScreen";
import AccountScreen from "./screens/auth/AccountScreen";
import PersonScreen from "./screens/PersonScreen";
import UpdateEmailScreen from "./screens/auth/UpdateEmailScreen";
import CreateUserScreen from "./screens/admin/CreateUserScreen";
import ManagePermissionsScreen from "./screens/admin/ManagePermissionsScreen";
import ManageApplicationScreen from "./screens/admin/ManageApplicationScreen";
import ManageUsersPermissionsScreen from "./screens/admin/ManageUsersPermissionsScreen";
import ManageUsersScreen from "./screens/admin/ManageUsersScreen";
import PolicyScreen from "./screens/PolicyScreen";
import TestScreen from "./screens/TestScreen";

const AppRoutes = [
    {
        index: true,
        element: <HomeScreen />
    },
    {
        path: '/login',
        element: <LoginScreen />
    },
    {
        path: '/counter',
        element: <CounterScreen/>
    },
    {
        path: '/person',
        element: <PersonScreen />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/test',
        element: <TestScreen />
    },
    {
        path: '/policy',
        element: <PolicyScreen />
    },
    {
        path: '/policy/:application',
        element: <PolicyScreen />
    }
];

export default AppRoutes;

export class AuthenticatedAppRoutes {
    static AppRoutes = [
        {
            path: '/update-password',
            element: <UpdatePasswordScreen />
        },
        {
            path: '/update-email',
            element: <UpdateEmailScreen />
        },
        {
            path: '/logout',
            element: <LogoutScreen />
        },
        {
            path: '/account',
            element: <AccountScreen />
        },
        {
            path: '/policy',
            element: <PolicyScreen />
        },
        {
            path: '/policy/:application',
            element: <PolicyScreen />
        }
    ];
}

export class AdminAppRoutes {
    static AppRoutes = [
        {
            path: '/create-user',
            element: <CreateUserScreen />
        },
        {
            path: '/manage-permissions',
            element: <ManagePermissionsScreen />
        },
        {
            path: '/manage-application',
            element: <ManageApplicationScreen />
        },
        {
            path: '/manage-users',
            element: <ManageUsersScreen />
        },
        {
            path: '/manage-users-permissions',
            element: <ManageUsersPermissionsScreen />
        }
    ]
}