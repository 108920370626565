import React, { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { AuthContext, AuthContextType } from '../../contexts/AuthContext';
import { Auth } from '../../models/auth/Auth';
import AuthService from '../../services/AuthService';
import CommonFunction from '../../utils/CommonFunctions';
import './LogoutScreen.css';

const LogoutScreen = () => {
    const navigation: NavigateFunction = useNavigate();
    const {auth, setAuth} = useContext(AuthContext) as AuthContextType;
    
    useEffect(() => {
        RemoveSession();
        localStorage.removeItem("authUuid");
        localStorage.removeItem("session");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.removeItem("username");
        setAuth(new Auth());
        onScreenLoad();
    });
    
    const onScreenLoad = async() => {
        await CommonFunction.delay(2000);
        navigation("/");
    };

    const RemoveSession = async() => {
        let sessionResponse = await AuthService.RemoveSession(auth);
        console.log("sessionResponse");
        console.log(sessionResponse);
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <h4 className="fw-bold mt-4">You have been Logged Out.</h4>
        </div>
    );
}

export default LogoutScreen;